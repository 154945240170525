import {createContext, useMemo, useReducer} from "react";
import {StorageReducer} from "./StorageReducer";
import FakeStore from "../commons/FakeStore";

export const StorageContext = createContext(null);

export const StorageContextProvider = ({ children }) => {
    const STORE_NAME = '__chooz_store';

    const localStore = JSON.parse(localStorage.getItem(STORE_NAME) || '{"language":"fr"}');
    const [storage, dispatch] = useReducer(StorageReducer, localStore);

    const contextValue = useMemo(() => {
        localStorage.setItem(STORE_NAME, JSON.stringify(storage));
        FakeStore.token = storage?.token; // for Authorization

        return { storage, dispatch };
    }, [storage, dispatch]);

    return (
        <StorageContext.Provider value={contextValue}>
            { children }
        </StorageContext.Provider>
    );
};
