import {useContext, useEffect} from "react";
import {StorageContext} from "../../storage/StorageContext";
import {logoutAction} from "../../storage/Actions";
import {useNavigate} from "react-router-dom";
import fakeStore from "../../commons/FakeStore";

const Logout = () => {
    const navigate = useNavigate();
    const { dispatch } = useContext(StorageContext);

    useEffect(() => {
        fakeStore.sessions = [];
        fakeStore.token = '';

        dispatch(logoutAction());
        navigate('/auth/login');
    }, []);

    return (
        <></>
    );
};

export default Logout;
