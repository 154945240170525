export const ptLang = {
    "votingLayout": {
        "Topbar Inscription": "Registrar",
        "Topbar Voter": "Votar",
        "Topbar Site Web": "Site",
        "Langue Anglais": "Inglês",
        "Langue Français": "Francês",
        "Langue portugais": "Português"
    },
    "waitingComponent": {
        "Patientez un instant": "Por favor, aguarde um momento"
    },
    "paymentInstanceComponent": {
        "Félicitations": "Parabéns 🥳! Seu pagamento foi processado com sucesso.",
        "Paiement échoué": "Pagamento falhou. Para quaisquer reclamações, entre em contato com o suporte."
    },
    "voteStartCountDownComponent": {
        "Jours": "dias",
        "Heures": "horas",
        "Minutes": "minutos",
        "Secondes": "segundos"
    },
    "votingCandidateItemComponent": {
        "Candidat/Nominé": "Candidato/Nomeado",
        "Numéro": "Número",
        "votes": "votos",
        "Voter": "Votar",
        "Votes": "Votos"
    },
    "paywayChooseModal": {
        "Entrez un numéro de télephone correct": "Digite um número de telefone correto.",
        "Payez avec": "Pague com",
        "Par quel moyen de paiement voulez vous effectuer le paiement": "Por qual método de pagamento você deseja efetuar o pagamento.",
        "Wave": "Wave",
        "Carte Bancaire": "Cartão de crédito",
        "Autres": "Outros",
        "Numéro de téléphone": "Número de telefone",
        "Entrez votre nom et votre email.": "Introduza o seu nome e e-mail.",
        "Entrez votre nom.": "Introduza o seu nome.",
        "E-mail": "E-mail",
        "Payer": "Pagar",
        "Nom et Prénons": "Nome e Sobrenome",
        "J'ai lu et j'accepte les termes et conditions d'utilisation de Chooz.": "Li e aceito os termos e condições de uso do Chooz.",
        "Adresse e-mail incorrecte.": "Endereço de e-mail inválido."
    },
    "votingCandidateModal": {
        "À propos": "Sobre",
        "Rien à dire": "Nada a dizer.",
        "Candidat/Nominé": "Número do Candidato/Indicado",
        "Voter": "Votar",
        "Votes": "Votos"
    },
    "votingSessionPage": {
        "Votez pour": "Vote para",
        "sur Chooz": "em Chooz",
        "pour l'emmener en tête du classement": "para levá-lo ao topo do ranking",
        "Lien du candidat copié. Vous pouvez maintenant le partager": "Link do candidato copiado. Agora você pode compartilhá-lo.",
        "Félicitations": "Parabéns",
        "Vous avez voté et poussé votre candidat favoris vers la victoire": "Você votou e impulsionou seu candidato favorito rumo à vitória",
        "Paiement échoué": "Pagamento falhou.",
        "Les votes ont pris fin pour cette étape": "A votação terminou para esta etapa.",
        "Erreur": "Erro",
        "Il semblerait que votre paiement ait échoué. Si vous avez été débité": "Parece que seu pagamento falhou. Se você foi debitado",
        "retenez le nombre de votes de votre candidat puis actualisez la page": "lembre-se do número de votos do seu candidato e então atualize a página",
        "Si vos votes n'ont pas été ajoutés au total, veuillez nous contacter": "Se seus votos não foram adicionados ao total, por favor, entre em contato conosco",
        "votes pour le candidat": "votos para o candidato",
        "Fin des votes": "Fim da votação",
        "Chargement": "Carregando",
        "Patientez un instant": "Por favor, aguarde um momento",
        "Rechercher un candidat": "Buscar um candidato",
        "Catégories": "Categorias",
        "Candidats/Nominés": "Candidato(s) / Indicado(s)",
        "Voir plus": "Ver mais",
        "Les votes n'ont pas encore débuté par ici": "A votação ainda não começou aqui. Volte mais tarde. E fique ligado em nossas diferentes redes sociais para não perder nada.",
        "Debut des votes dans": "Início da votação em"
    },
    "enrollPage": {
        "Le fichier n'a pas pu être chargé. Veuillez réessayer": "O arquivo não pôde ser carregado. Por favor, tente novamente.",
        "Votre inscription n'a pas été retrouvée": "Sua inscrição não pôde ser encontrada. Se você fez um pagamento, pode ter falhado. Para quaisquer reclamações, entre em contato com o suporte.",
        "Paiement introuvable": "Pagamento não encontrado",
        "Inscrivez vous": "Inscreva-se",
        "Inscrivez vous en tant que candidat pour participer à cette Session": "Inscreva-se como candidato para participar desta Sessão",
        "Entrez votre nom": "Digite seu nome",
        "Adresse email incorrecte": "Endereço de e-mail incorreto",
        "Numéro de téléphone incorrect": "Número de telefone incorreto",
        "Entrez votre numéro de téléphone": "Digite seu número de telefone",
        "Choisissez une catégorie": "Escolha uma categoria",
        "Charger votre photo": "Carregar sua foto",
        "Choisir": "Escolher",
        "Nom & prénoms": "Nome & sobrenome",
        "Votre nom et prénoms": "Seu nome e sobrenome",
        "Date de naissance": "Data de nascimento",
        "E-mail": "E-mail",
        "Téléphone": "Telefone",
        "Montant de l'inscription": "Valor da inscrição",
        "M'inscrire": "Inscreva-me",
        "Félicitations": "Parabéns",
        "Votre identifiant": "Seu ID",
        "Votre inscription a bien été prise en compte": "Sua inscrição foi processada com sucesso. Estamos felizes em tê-lo como um de nossos candidatos. Até breve. Entraremos em contato se necessário para comunicar informações ou fazer perguntas.",
        "Aller au formulaire": "Ir para o formulário",
        "Un instant": "Um momento",
        "Terminé": "Concluído",
        "Un peu de patience": "Um pouco de paciência",
        "Vous arrivez sans doute trop tard": "Você provavelmente está chegando tarde demais. Infelizmente, as inscrições acabaram de terminar. Os próximos passos serão comunicados em breve. Fique ligado para não perder nada. Até breve.",
        "Les inscriptions n'ont pas encore débuté": "As inscrições ainda não começaram. Volte quando for hora de se inscrever para enviar sua inscrição. Fique ligado em nossas diversas redes sociais e site para não perder nada. Até breve.",
        "Début dans": "Começa em",
        "Inscription du candidat": "Inscrição do candidato"
    }
}