import './App.css';
import {RouterProvider} from "react-router-dom";
import {router} from "./pages/router";
import {StorageContextProvider} from "./storage/StorageContext";
import { i18nInit } from './commons/i18n/init';

i18nInit('fr');

function App() {
	return (
		<StorageContextProvider>
			<RouterProvider router={router} />
		</StorageContextProvider>
	);
}

export default App;
