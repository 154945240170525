import {Link, Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import {useContext} from "react";
import {StorageContext} from "../storage/StorageContext";
import {BiHeart, BiMenu} from "react-icons/bi";
import {Avatar, Dropdown} from "flowbite-react";

const links = [
    { path: '/account', label: 'Accueil' },
    { path: '/account/sessions', label: 'Sessions' },
    { path: '/account/sessions/steps', label: 'Étapes' },
    { path: '/account/sessions/candidates', label: 'Candidats' },
    { path: '/account/sessions/votes', label: 'Votes' },
    { path: '/account/withdraw', label: 'Retraits' },
    { path: '/account/settings', label: 'Paramètres' },
];

const PrivateLayout = () => {
    const { storage } = useContext(StorageContext);
    const location = useLocation();
    const navigate = useNavigate();

    return storage.user ? (
        // bg-[#F9FAFB]
        <div className={`h-screen w-full overflow-auto flex flex-col pt-[69px]`}>
            <header className={`bg-gray-900 fixed w-full top-0 left-0 z-10`}>
                <div className={`max-w-screen-2xl mx-auto px-5 py-[17px] flex items-center`}>
                    {/* brand */}
                    <div className={`shrink-0`}>
                        <img src="/assets/images/logo-nobg.png" className={`w-24 rounded-md`} alt=""/>
                    </div>

                    {/* menu */}
                    <ul className={`m-0 p-0 ml-16 gap-7 hidden lg:flex`}>
                        {links.map((item, idx) => (
                            <li key={idx + 1}>
                                <Link
                                    to={item.path}
                                    className={`
                                        text-white text-base font-medium
                                        ${item.path === location.pathname ? '!text-orange-500 border-l-2 border-orange-500 pl-2' : ''}
                                    `}
                                >{item.label}</Link>
                            </li>
                        ))}
                    </ul>

                    {/* aside */}
                    <div className={`ml-auto flex items-center shrink-0`}>
                        <h4 className={`text-sm text-white mr-3 font-bold`}>{storage.user.name.split(' ')[0]}</h4>
                        <div className={``}>
                            <Dropdown
                                inline
                                className={`w-[200px]`}
                                renderTrigger={() => <Avatar size="md" alt="User settings" img="/assets/images/avatar.png" rounded/>}
                                label=""
                            >
                                <Dropdown.Header>
                                    <span className="block text-sm font-semibold">{storage.user.name}</span>
                                    <span className="block truncate text-sm text-orange-400 font-medium">
                                      {storage.user.email || ''}
                                    </span>
                                </Dropdown.Header>
                                <Dropdown.Item onClick={() => navigate('/account/settings/profile')}>Profil</Dropdown.Item>
                                <Dropdown.Item onClick={() => navigate('/account/settings/company')}>Paramètres</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={() => navigate('/logout')}>Déconnexion</Dropdown.Item>
                            </Dropdown>
                        </div>
                        <div className={`block lg:hidden ml-4`}>
                            <Dropdown
                                inline
                                className={`w-[200px]`}
                                renderTrigger={() => <BiMenu size={25} color="#fff" />}
                                label=""
                            >
                                {links.map((item, idx) => (
                                    <Dropdown.Item key={idx + 1} className={`hover:text-orange-500`}>
                                        <Link to={item.path}>{item.label}</Link>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </header>
            <div className={`max-w-screen-2xl mx-auto px-5 pb-4 w-full`}>
                <Outlet />
            </div>
            <footer className={`w-full mt-auto bg-gray-100 py-3`}>
                <div className={`max-w-screen-2xl px-5 mx-auto`}>
                    <p className={`text-xs text-center text-gray-400 font-semibold`}>
                        With <BiHeart className={`text-orange-500 inline-block`} />
                        &nbsp;by Sarrux
                    </p>
                </div>
            </footer>
        </div>
    ) : (
        <Navigate to="/auth/login" />
    );
};

export default PrivateLayout;
