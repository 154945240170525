export const enLang = {
    "votingLayout": {
        "Topbar Inscription": "Register",
        "Topbar Voter": "Vote",
        "Topbar Site Web": "Website",
        "Langue Anglais": "English",
        "Langue Français": "French",
        "Langue portugais": "Portuguese"
    },
    "waitingComponent": {
        "Patientez un instant": "Please wait a moment"
    },
    "paymentInstanceComponent": {
        "Félicitations": "Congratulations 🥳! Your payment has been successfully processed.",
        "Paiement échoué": "Payment failed. For any complaints, please contact support."
    },
    "voteStartCountDownComponent": {
        "Jours": "days",
        "Heures": "hours",
        "Minutes": "minutes",
        "Secondes": "seconds"
    },
    "votingCandidateItemComponent": {
        "Candidat/Nominé": "Candidate/Nominee",
        "Numéro": "Number",
        "votes": "votes",
        "Voter": "Vote",
        "Votes": "Votes"
    },
    "paywayChooseModal": {
        "Entrez un numéro de télephone correct": "Enter a correct phone number.",
        "Payez avec": "Pay with",
        "Par quel moyen de paiement voulez vous effectuer le paiement": "By which payment method do you want to make the payment.",
        "Wave": "Wave",
        "Carte Bancaire": "Credit Card",
        "Autres": "Others",
        "Numéro de téléphone": "Phone number",
        "Entrez votre nom et votre email.": "Enter you fullname and e-mail.",
        "Entrez votre nom.": "Enter you fullname.",
        "Payer": "Pay",
        "E-mail": "Email",
        "Nom et Prénons": "First and Last Name",
        "J'ai lu et j'accepte les termes et conditions d'utilisation de Chooz.": "I have read and accept the terms and conditions of use of Chooz.",
        "Adresse e-mail incorrecte.": "Invalid email address."
    },
    "votingCandidateModal": {
        "À propos": "About",
        "Rien à dire": "Nothing to say.",
        "Candidat/Nominé": "Candidate/Nominee Number",
        "Voter": "Vote",
        "Votes": "Votes"
    },
    "votingSessionPage": {
        "Votez pour": "Vote for",
        "sur Chooz": "on Chooz",
        "pour l'emmener en tête du classement": "to get him/her to the top of the ranking",
        "Lien du candidat copié. Vous pouvez maintenant le partager": "Candidate link copied. You can now share it.",
        "Félicitations": "Congratulations",
        "Vous avez voté et poussé votre candidat favoris vers la victoire": "You voted and pushed your favorite candidate towards victory",
        "Paiement échoué": "Payment failed.",
        "Les votes ont pris fin pour cette étape": "Voting has ended for this stage.",
        "Erreur": "Error",
        "Il semblerait que votre paiement ait échoué. Si vous avez été débité": "It seems that your payment failed. If you have been debited",
        "retenez le nombre de votes de votre candidat puis actualisez la page": "remember the number of votes for your candidate and then refresh the page",
        "Si vos votes n'ont pas été ajoutés au total, veuillez nous contacter": "If your votes have not been added to the total, please contact us",
        "votes pour le candidat": "votes for the candidate",
        "Fin des votes": "End of voting",
        "Chargement": "Loading",
        "Patientez un instant": "Please wait a moment",
        "Rechercher un candidat": "Search for a candidate",
        "Catégories": "Categories",
        "Candidats/Nominés": "Candidate(s) / Nominee(s)",
        "Voir plus": "See more",
        "Les votes n'ont pas encore débuté par ici": "Voting has not yet started here. Come back later. And stay tuned on our different social networks to not miss anything.",
        "Debut des votes dans": "Start of voting in"
    },
    "enrollPage": {
        "Le fichier n'a pas pu être chargé. Veuillez réessayer": "The file could not be loaded. Please try again.",
        "Votre inscription n'a pas été retrouvée": "Your registration could not be found. If you have made a payment, it may have failed. For any complaints, please contact support.",
        "Paiement introuvable": "Payment not found",
        "Inscrivez vous": "Sign up",
        "Inscrivez vous en tant que candidat pour participer à cette Session": "Sign up as a candidate to participate in this Session",
        "Entrez votre nom": "Enter your name",
        "Adresse email incorrecte": "Incorrect email address",
        "Numéro de téléphone incorrect": "Incorrect phone number",
        "Entrez votre numéro de téléphone": "Enter your phone number",
        "Choisissez une catégorie": "Choose a category",
        "Charger votre photo": "Upload your photo",
        "Choisir": "Choose",
        "Nom & prénoms": "Name & surname",
        "Votre nom et prénoms": "Your name and surname",
        "Date de naissance": "Date of birth",
        "E-mail": "Email",
        "Téléphone": "Phone",
        "Montant de l'inscription": "Registration fee",
        "M'inscrire": "Sign me up",
        "Félicitations": "Congratulations",
        "Votre identifiant": "Your ID",
        "Votre inscription a bien été prise en compte": "Your registration has been successfully processed. We are delighted to have you as one of our candidates. See you very soon. We will contact you if necessary to communicate information or to ask you questions.",
        "Aller au formulaire": "Go to the form",
        "Un instant": "A moment",
        "Terminé": "Done",
        "Un peu de patience": "A little patience",
        "Vous arrivez sans doute trop tard": "You are probably arriving too late. Unfortunately, registrations have just ended. The next steps will be communicated in due course. Stay tuned so you don't miss anything. See you very soon.",
        "Les inscriptions n'ont pas encore débuté": "Registrations have not yet begun. Come back when it's time to register to submit your application. Stay tuned on our various social networks and website so you don't miss anything. See you very soon.",
        "Début dans": "Starts in",
        "Inscription du candidat": "Candidate registration"
    }
}