import {Navigate, Outlet} from "react-router-dom";
import {useContext} from "react";
import {StorageContext} from "../storage/StorageContext";

const AuthLayout = () => {
    const { storage } = useContext(StorageContext);

    return (
        <>
        {!storage.user ? (
            <div className={`h-screen w-full overflow-hidden flex`}>
                <div
                    className={`w-1/2 h-full py-3 hidden lg:block`}
                    style={{ backgroundImage: 'url(/assets/images/bg-1.jpg)', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
                >
                </div>
                <div className={`flex-1 h-full shrink-0 flex`}>
                    <Outlet />
                </div>
            </div>
        ) : (
            <Navigate to="/account" />
        )}
        </>
    );
};


export default AuthLayout;
