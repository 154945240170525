export const LOGIN_ACTION = 'USER_LOGIN';
export const LOGOUT_ACTION = 'USER_LOGOUT';
export const UPDATE_VOTING_COMPANY = 'UPDATE_VOTING_COMPANY';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_CGU_ACCEPT = 'UPDATE_CGU_ACCEPT';
export const SET_VISITOR_INFOS = 'SET_VISITOR_INFOS';

export const loginAction = (payload) => {
    return { type: LOGIN_ACTION, payload };
};

export const logoutAction = () => {
    return { type: LOGOUT_ACTION };
};

export const updateVotingCompany = (payload) => {
    return { type: UPDATE_VOTING_COMPANY, payload };
};

export const updateLanguage = (payload) => {
    return { type: UPDATE_LANGUAGE, payload };
};

export const updateCGUAccept = (payload) => {
    return { type: UPDATE_CGU_ACCEPT, payload };
};

export const setVisitorInfos = (payload) => {
    return { type: SET_VISITOR_INFOS, payload };
};