export const frLang = {
    "votingLayout": {
        "Topbar Inscription": "Inscription",
        "Topbar Voter": "Voter",
        "Topbar Site Web": "Site Web",
        "Langue Anglais": "Anglais",
        "Langue Français": "Français",
        "Langue Portugais": "Portugais",
    },
    "waitingComponent": {
        "Patientez un instant": "Patientez un instant"
    },
    "paymentInstanceComponent": {
        "Félicitations": "Félicitations 🥳 ! Votre paiement a bien été pris en compte.",
        "Paiement échoué": "Le paiement n\'a pas abouti. Pour toute reclamation, veuillez contacter le support."
    },
    "voteStartCountDownComponent": {
        "Jours": "jours",
        "Heures": "heures",
        "Minutes": "minutes",
        "Secondes": "secondes"
    },
    "votingCandidateItemComponent": {
        "Candidat/Nominé": "Candidat(e) / Nominé(e)",
        "Numéro": "Numéro",
        "votes": "votes",
        "Voter": "Voter",
        "Votes": "Votes",
    },
    "paywayChooseModal": {
        "Entrez un numéro de télephone correct": "Entrez un numéro de télephone correct.",
        "Payez avec": "Payez avec",
        "Par quel moyen de paiement voulez vous effectuer le paiement": "Par quel moyen de paiement voulez vous effectuer le paiement.",
        "Wave": "Wave",
        "Carte Bancaire": "Carte de crédit",
        "Autres": "Autres",
        "Numéro de téléphone": "Numéro de téléphone",
        "E-mail": "Email",
        "Entrez votre nom et votre email.": "Entrez votre nom et votre email.",
        "Entrez votre nom.": "Entrez votre nom.",
        "E-mail": "Email",
        "Payer": "Payer",
        "Carte bancaire": "Carte bancaire",
        "Nom et Prénons": "Nom et Prénons",
        "J'ai lu et j'accepte les termes et conditions d'utilisation de Chooz.": "J'ai lu et j'accepte les termes et conditions d'utilisation de Chooz.",
        "Adresse e-mail incorrecte.": "Adresse e-mail incorrecte."
    },
    "votingCandidateModal": {
        "À propos": "À propos",
        "Rien à dire": "Rien à dire.",
        "Candidat/Nominé": "Numéro de candidat(e) / Nominé(e)",
        "Voter": "Voter",
        "Votes": "Votes",
    },
    "votingSessionPage": {
        "Votez pour": "Votez pour",
        "sur Chooz": "sur Chooz",
        "pour l'emmener en tête du classement": "pour l'emmener en tête du classement",
        "Lien du candidat copié. Vous pouvez maintenant le partager": "Lien du candidat copié. Vous pouvez maintenant le partager.",
        "Félicitations": "Félicitations",
        "Vous avez voté et poussé votre candidat favoris vers la victoire": "Vous avez voté et poussé votre candidat favoris vers la victoire",
        "Paiement échoué": "Le paiement n\'a pas abouti.",
        "Les votes ont pris fin pour cette étape": "Les votes ont pris fin pour cette étape.",
        "Erreur": "Erreur",
        "Il semblerait que votre paiement ait échoué. Si vous avez été débité": "Il semblerait que votre paiement ait échoué. Si vous avez été débité",
        "retenez le nombre de votes de votre candidat puis actualisez la page": "retenez le nombre de votes de votre candidat puis actualisez la page",
        "Si vos votes n'ont pas été aoutés au total, veuillez nous contacter": "Si vos votes n\'ont pas été aoutés au total, veuillez nous contacter",
        "votes pour le candidat": "votes pour le candidat",
        "Fin des votes": "Fin des votes",
        "Chargement": "Chargement",
        "Patientez un instant": "Patientez un instant",
        "Rechercher un candidat": "Rechercher un candidat",
        "Catégories": "Catégories",
        "Candidats/Nominés": "Candidat.e(s) / Nominé.e(s)",
        "Voir plus": "Voir plus",
        "Les votes n'ont pas encore débuté par ici": "Les votes n'ont pas encore débuté par ici. Revenez plus tard. Et surtout restez à l'écoute sur nos differents reseaux sociaux pour ne rien manquer.",
        "Debut des votes dans": "Debut des votes dans"
    },
    "enrollPage": {
        "Le fichier n'a pas pu être chargé. Veuillez réessayer": "Le fichier n'a pas pu être chargé. Veuillez réessayer.",
        "Votre inscription n'a pas été retrouvée": "Votre inscription n\'a pas été retrouvée. Si vous avez effectué un paiement il a peut-être échoué. Pour toute reclamation veuillez contacter le support.",
        "Paiement introuvable": "Paiement introuvable",
        "Inscrivez vous": "Inscrivez vous",
        "Inscrivez vous en tant que candidat pour participer à cette Session": "Inscrivez vous en tant que candidat pour participer à cette Session",
        "Entrez votre nom": "Entrez votre nom",
        "Adresse email incorrecte": "Adresse email incorrecte",
        "Numéro de téléphone incorrect": "Numéro de téléphone incorrect",
        "Entrez votre numéro de téléphone": "Entrez votre numéro de téléphone",
        "Choisissez une catégorie": "Choisissez une catégorie",
        "Charger votre photo": "Charger votre photo",
        "Choisir": "Choisir",
        "Nom & prénoms": "Nom & prénoms",
        "Votre nom et prénoms": "Votre nom et prénoms",
        "Date de naissance": "Date de naissance",
        "E-mail": "E-mail",
        "Téléphone": "Téléphone",
        "Montant de l'inscription": "Montant de l'inscription",
        "M'inscrire": "M'inscrire",
        "Félicitations": "Félicitations",
        "Votre identifiant": "Votre identifiant",
        "Votre inscription a bien été prise en compte": "Votre inscription a bien été prise en compte. Nous somme ravis de vous compter parmis nos candidats. Rendez-vous très bientôt. Nous vous contacterons si besoin pour vous communiquer des informations ou pour vous en demander.",
        "Aller au formulaire": "Aller au formulaire",
        "Un instant": "Un instant",
        "Terminé": "Terminé",
        "Un peu de patience": "Un peu de patience",
        "Vous arrivez sans doute trop tard": "Vous arrivez sans doute trop tard. Hélas. Les inscriptions on pris fin il y'a peu. La suite des évènements sera communiqué le moment venu. Restez à l'écoute pour ne rien manquer. À très vite.",
        "Les inscriptions n'ont pas encore débuté": "Les inscriptions n'ont pas encore débuté. Revenez le moment venu pour vous inscrire afin d'enregistrer votre candidature. Restez à l'écoute sur nos differents reseaux sociaux et site web afin de ne rien manquer. À très vite.",
        "Début dans": "Début dans",
        "Inscription du candidat": "Inscription du candidat"
    }
}