import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { frLang } from "./fr.lang";
import { enLang } from "./en.lang";
import { ptLang } from "./pt.lang";

export const i18nInit = (defaultLang='fr') => {
    i18n
	.use(initReactI18next)
	.init({
		resources: {
			en: enLang,
			fr: frLang,
			pt: ptLang
		},
		lng: defaultLang,
		fallbackLng: "fr",
		interpolation: {
			escapeValue: false
		}
	});
};