import {useContext, useEffect} from "react";
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import {BiHeart, BiMenu} from "react-icons/bi";
import {Dropdown} from "flowbite-react";
import { useTranslation } from 'react-i18next';
import {StorageContext} from "../storage/StorageContext";
import { updateLanguage } from "../storage/Actions";
import i18next from "i18next";

const VotingLayout = () => {
    const params = useParams();
    const location = useLocation();
    const {storage, dispatch} = useContext(StorageContext);
    const { t } = useTranslation('votingLayout');

    const links = [
        { path: `/sess/${params.sess_id}/enroll`, label: t('Topbar Inscription') },
        { path: `/sess/${params.sess_id}/vote`, label: t('Topbar Voter') },
        { path: storage?.voting_company?.website_url || '', label: t('Topbar Site Web') }
    ];

    const languages = {
        fr: { flag: 'FR', abbr: 'fr', name: t('Langue Français') },
        en: { flag: 'GB', abbr: 'en', name: t('Langue Anglais') },
        pt: { flag: 'PT', abbr: 'pt', name: t('Langue Portugais') },
    };

    const doUpdateLanguage = (lang) => {
        i18next.changeLanguage(lang);
        dispatch(updateLanguage(lang));
    };

    useEffect(() => {
        i18next.changeLanguage(storage.language);
    }, []);

    return (
        <>
            <header className={`bg-gray-900 fixed w-full top-0 left-0 z-10`}>
                <div className={`max-w-screen-2xl mx-auto px-5 py-[17px] flex items-center`}>
                    {/* brand */}
                    <Link to="/" className={`shrink-0 block`}>
                        <img src="/assets/images/logo-nobg.png" className={`w-24 rounded-md`} alt=""/>
                    </Link>
                    {/*menu*/}
                    <ul className={`m-0 p-0 gap-7 hidden lg:flex ml-auto`}>
                        {links.map((item, idx) => (
                            <li key={idx + 1}>
                                <Link
                                    to={item.path}
                                    className={`
                                        text-white text-base font-medium
                                        ${item.path === location.pathname ? '!text-orange-500 border-l-2 border-orange-500 pl-2' : ''}
                                    `}
                                >{item.label}</Link>
                            </li>
                        ))}
                    </ul>

                    {/* aside */}
                    <div className={`flex lg:hidden items-center shrink-0 ml-auto`}>
                        <div className={`ml-4`}>
                            <Dropdown
                                inline
                                className={`w-[200px]`}
                                renderTrigger={() => <BiMenu size={25} color="#fff" />}
                                label=""
                            >
                                {links.map((item, idx) => (
                                    <Dropdown.Item key={idx + 1} className={`hover:text-orange-500`}>
                                        <Link to={item.path}>{item.label}</Link>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown>
                        </div>
                    </div>

                    {/* language select */}
                    <div className={`ml-4`}>
                        <Dropdown
                            inline
                            className={`w-[200px]`}
                            renderTrigger={() => (
                                <img
                                    src={`https://flagsapi.com/${languages[(storage.language || 'fr')].flag.toUpperCase()}/flat/64.png`}
                                    className={`w-7`}
                                />
                            )}
                            label=""
                        >
                            {Object.values(languages).map((item, idx) => (
                                <Dropdown.Item
                                    key={idx + 1}
                                    className={`hover:text-orange-500 flex items-center`}
                                    onClick={() => doUpdateLanguage(item.abbr)}
                                >
                                    <img
                                        src={`https://flagsapi.com/${item.flag}/flat/64.png`}
                                        className={`w-7 mr-2`}
                                    />
                                    <span>{item.name}</span>
                                </Dropdown.Item>
                            ))}
                        </Dropdown>
                    </div>
                </div>
            </header>

            <Outlet />

            <footer className={`w-full mt-auto bg-gray-100_ py-3`}>
                <div className={`max-w-screen-2xl px-5 mx-auto`}>
                    <p className={`text-xs text-center text-gray-400 font-semibold`}>
                        With <BiHeart className={`text-orange-500 inline-block`} />
                        &nbsp;by Sarrux
                    </p>
                </div>
            </footer>
        </>
    );
};

export default VotingLayout;
