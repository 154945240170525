import {Suspense} from "react";
import {Spinner} from "flowbite-react";

const LazyFallback = () => {

    return (
        <>
        <div className={`h-screen w-screen fixed top-0 left-0 bg-[rgba(255,255,255,0.6)] flex items-center justify-center`}>
            <Spinner size="lg" color="purple" />
        </div>
        </>
    );
};

export const lazyRoute = (Component) => {

    return (
        <Suspense fallback={<LazyFallback />}>
            <Component />
        </Suspense>
    );
};
