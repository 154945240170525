import {lazy} from "react";
import {createBrowserRouter, Navigate} from "react-router-dom";
import AuthLayout from "../components/AuthLayout";
import PrivateLayout from "../components/PrivateLayout";
import Logout from "./auth/Logout";
import VotingLayout from "../components/VotingLayout";
import {lazyRoute} from "./lazyRoute";

const Home = lazy(() => import('./home/Home'));
const Login = lazy(() => import('./auth/Login'));
const Register = lazy(() => import('./auth/Register'));
const Restore = lazy(() => import('./auth/Restore'));
const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Sessions = lazy(() => import('./sessions/Sessions'));
const Steps = lazy(() => import('./steps/Steps'));
const Candidates = lazy(() => import('./candidates/Candidates'));
const Votes = lazy(() => import('./votes/Votes'));
const Withdraw = lazy(() => import('./withdraw/Withdraw'));
const Settings = lazy(() => import('./settings/Settings'));
const VotingSession = lazy(() => import('./voting-pages/session/VotingSession'));
const Enroll = lazy(() => import('./voting-pages/enroll/Enroll'));

export const router = createBrowserRouter([
    {
        path: '/',
        errorElement: <Navigate to="/" />,
        children: [
            { path: '', element: lazyRoute(Home) },
            {
                path: 'auth',
                element: <AuthLayout />,
                children: [
                    { path: 'login', element: lazyRoute(Login) },
                    { path: 'register', element: lazyRoute(Register) },
                    { path: 'register/:status', element: lazyRoute(Register) },
                    { path: 'restore-password', element: lazyRoute(Restore) }
                ]
            },
            {
                path: 'account',
                element: <PrivateLayout />,
                children: [
                    { path: '', element: lazyRoute(Dashboard) },
                    { path: 'sessions', element: lazyRoute(Sessions) },
                    { path: 'sessions/steps', element: lazyRoute(Steps) },
                    { path: 'sessions/:sess_id/steps', element: lazyRoute(Steps) },
                    { path: 'sessions/candidates', element: lazyRoute(Candidates) },
                    { path: 'sessions/:sess_id/candidates', element: lazyRoute(Candidates) },
                    { path: 'sessions/votes', element: lazyRoute(Votes) },
                    { path: 'sessions/:sess_id/votes', element: lazyRoute(Votes) },
                    { path: 'withdraw', element: lazyRoute(Withdraw) },
                    { path: 'settings/:section?', element: lazyRoute(Settings) },
                ]
            },
            { path: 'logout', element: <Logout /> }
        ]
    },
    {
        path: '/sess',
        element: <VotingLayout />,
        errorElement: <Navigate to="/" />,
        children: [
            { path: '', element: <Navigate to="/" /> },
            { path: ':sess_id/vote', element: lazyRoute(VotingSession) },
            { path: ':sess_id/vote/:category_id', element: lazyRoute(VotingSession) },
            { path: ':sess_id/enroll', element: lazyRoute(Enroll) },
        ]
    }
]);
