import {LOGIN_ACTION, LOGOUT_ACTION, SET_VISITOR_INFOS, UPDATE_CGU_ACCEPT, UPDATE_LANGUAGE, UPDATE_VOTING_COMPANY} from "./Actions";

export const StorageReducer = (state, action) => {

    switch (action.type) {
        case LOGIN_ACTION:
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user
            };

        case LOGOUT_ACTION:
            return {};

        case UPDATE_VOTING_COMPANY:
            return {
                ...state,
                voting_company: { ...(state.voting_company ||  {}), ...action.payload }
            };

        case UPDATE_CGU_ACCEPT:
            return {
                ...state,
                cgu_accepted_at: action.payload
            };

        case UPDATE_LANGUAGE:
            return {
                ...state,
                language: action.payload
            };

        case SET_VISITOR_INFOS:
            return {
                ...state,
                visitor: action.payload
            };

        default:
            return state;
    }

}
